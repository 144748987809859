// context/WalletContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAddress, useConnectionStatus } from '@thirdweb-dev/react';
import { apiURI } from '../config';

export const WalletContext = createContext();

const WalletProvider = ({ children }) => {
    const address = useAddress();
    const connectionStatus = useConnectionStatus();

    const [user, setUser] = useState(() => {
        // Get user data from session storage if available
        const storedUser = sessionStorage.getItem('user');
        return storedUser !== undefined ? JSON.parse(storedUser) : null;
    });


    const updateUser = (userData) => {
        setUser(userData);
        sessionStorage.setItem('user', JSON.stringify(userData));
    }

    useEffect(() => {
        const fetchUser = async () => {
            if (connectionStatus === "connected" && address) {
                try {
                    // Check if user already exists
                    const responseData = await getReviewer(address);
                    // console.log(responseData, "RESPONSE DATA");

                    if (responseData) {
                        // console.log("USER FOUND", responseData);
                        setUser(responseData);
                        sessionStorage.setItem('user', JSON.stringify(responseData));
                    } else {
                        // Create a new user
                        const newUser = await createReviewer(address);
                        // console.log("NEW USER CREATED", newUser, address);
                        if (newUser) {
                            setUser(newUser);
                            sessionStorage.setItem('user', JSON.stringify(newUser));
                        } else {
                            console.error("Failed to create new user");
                        }
                    }
                } catch (error) {
                    console.error('Failed to fetch or create user', error);
                }
            }
        };

        fetchUser();
    }, [connectionStatus, address]);

    const getReviewer = async (address) => {
        try {
            var query = `
                  query GetReviewerDetails($wallet_address: String) {
                        getReviewerDetails(wallet_address: $wallet_address) {
                            wallet_address
                            level_cleared
                            _id
                        }
                    }
                      `;
            const response = await fetch(apiURI.URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "x-power": process.env.REACT_APP_POWER_KEY,
                    "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
                    "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
                    "x-range-name": process.env.REACT_APP_RANGE_NAME,
                },
                body: JSON.stringify({
                    query,
                    variables: {
                        wallet_address: address.toString().toLowerCase()
                    },
                }),
            })
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const json = await response.json();
            // console.log("API Response:", json.data.getReviewerDetails); // Log the raw API response

            // Return the data directly
            return json?.data?.getReviewerDetails || null;
        } catch (error) {
            console.log(error);
        }
    }

    const createReviewer = async (address) => {
        try {
            var query = `
                mutation CreateReviewer($walletAddress: String) {
                    createReviewer(wallet_address: $walletAddress) {
                    _id
                    wallet_address
                    level_cleared
                    }
                }
                      `;
            const response = await fetch(apiURI.URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "x-power": process.env.REACT_APP_POWER_KEY,
                    "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
                    "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
                    "x-range-name": process.env.REACT_APP_RANGE_NAME,
                },
                body: JSON.stringify({
                    query,
                    variables: {
                        walletAddress: address.toString().toLowerCase()
                    },
                }),
            })
            // Check for a successful response
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data.data.createReviewer, "createReviewer"); // Log the created reviewer

            // Check if createReviewer is defined before returning
            return data?.data?.createReviewer || null;

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <WalletContext.Provider value={{ user, updateUser }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useConnectAddress = () => useContext(WalletContext);
export default WalletProvider;