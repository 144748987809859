import { Routes, Route, Navigate } from "react-router-dom";

// Basic Styling
import "./css/styles.css";

//CSS Bootstrap

import "./css/icons.min.css";
import "./css/style-dark.min.css";
import "./css/customize.css";
import "./css/bootstrap-dark.min.css";

import "react-toastify/dist/ReactToastify.css";

import { apiURI } from "./config";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

import { ToastContainer } from "react-toastify";

import { BinanceTestnet } from "@thirdweb-dev/chains";

import {
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  localWallet,
  embeddedWallet,
} from "@thirdweb-dev/react";

//COMPONENTS
import HomePage from "./Pages/HomePage/HomePage";
import Resources from "./Pages/Resources/Resources";
import QuizModule from "./Pages/Quiz/QuizModule";
import Profile from "./Pages/Profile/Profile";
import Review2Earn from "./Pages/Review2Earn/Review2Earn";
import ProductDetail from "./Pages/Review2Earn/ProductDetail";

import ProtectedRoute from "./Routes/ProtectedRoute";
import PublicRoute from "./Routes/PublicRoute";
import WalletProvider from "./Context/WalletContext";

function App() {
  const link = createUploadLink({
    uri: apiURI.URL,
  });

  const cache = new InMemoryCache();

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "x-power": process.env.REACT_APP_POWER_KEY,
        "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
        "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
        "x-range-name": process.env.REACT_APP_RANGE_NAME,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(link),
    cache,
    resolvers: {},
    connectToDevTools: true,
  });

  return (
    <ApolloProvider client={client}>
      <ThirdwebProvider
        activeChain={BinanceTestnet}
        supportedChains={[BinanceTestnet]}
        clientId="322ef3b7bb795b36b25427bb8e6ff22e"
        supportedWallets={[
          metamaskWallet({ recommended: true }),
          coinbaseWallet(),
          walletConnect(),
          localWallet(),
          embeddedWallet(),
        ]}
        dAppMeta={{
          name: "Watcher Finance",
          description: "Product Validation Platform",
          logoUrl:
            "https://dev.sentinel.watcher.finance/static/media/sentinel-logo.b7b19edd278111be4ee7.png",
          url: "https://dev.sentinel.watcher.finance",
          isDarkMode: false,
        }}
      >
        <WalletProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/resources" element={<Resources />} />
              <Route
                path="/quiz"
                element={<ProtectedRoute element={<QuizModule />} />}
              />
              <Route
                path="/profile"
                element={<ProtectedRoute element={<Profile />} />}
              />
              <Route
                path="/review2earn"
                element={<ProtectedRoute element={<Review2Earn />} />}
              />
              {/* <Route
                path="/review2earn/products/:id"
                element={<ProtectedRoute element={<ProductDetail />} />}
              /> */}
              <Route path="/review2earn/products/:id" element={<PublicRoute />}>
                <Route index element={<ProductDetail />} />
              </Route>

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </WalletProvider>
      </ThirdwebProvider>
    </ApolloProvider>
  );
}

export default App;
